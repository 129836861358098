main {
  min-height: 80vh;
}

.rating span {
  margin: 0.1rem;
}

.rating svg {
  color: #f8e825;
}

.rating-text {
  font-size: 0.8rem;
  font-weight: 600;
  padding-left: 0.5rem;
}

.product-title {
  height: 2.5em; /* Set a fixed height */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for long text */
  white-space: nowrap; /* Prevent wrapping */
}

.product-image {
  height: 13em;
}

table td,
table th {
  text-align: center;
}

.review {
  margin-top: 30px;
}

.review h2 {
  font-size: 24px;
  background: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
}

.review button {
  margin-top: 10px;
}

.carousel-caption {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}


.column_log_formateur, .column_langue, .column_devise, 
.column_formations, .column_log_user, .column_categories, 
.column_search, .column_licorne, .column_panier{
    border: 0.1px solid grey;
    background-color: rgb(247, 244, 244);
}

.column_formations_mobile, .column_log_formateur_mobile, .column_langue_mobile, .column_devise_mobile, 
.column_log_user_mobile, .column_licorne_mobile, .column_panier_mobile,
.column_categories_mobile, .column_search_mobile, .column_log_invite_mobile{
    border: 0.1px solid grey;
    background-color: rgb(247, 244, 244);  
}

.row_licorne{
    border: 0.1px solid grey;
    background-color: rgb(247, 244, 244);  
}

.column-search{
    text-align: bottom;
}

.produit{
    border: 0.1px solid green;
}

.langue {
    text-align: center;
} 

.devise {
    text-align: center;
} 

.bas{
    border: 0.1px solid grey;
}

.formation_presentiel{
    font-size: 15px;
    font-weight: bold;
    color: rgb(71, 6, 6);
    text-align: center;
}

.formation_enligne{
  font-size: 15px;
  font-weight: bold;
  text-align: center;
}

.formations_mobile{
    font-size: 15px;
    font-weight: bold;
    color: rgb(71, 6, 6);
}

.login_formateur_mobile, .login_user_mobile{
    font-size: 12px;
}

h3{
    padding: 1rem 0;
}

.rating span{
    margin: 0.1rem;
} 

.formation_en_ligne{
  text-align: center;
  /*font-size: 20px;*/
}   

.autre_produit{
  text-align: center;
  /*font-size: 20px;*/
} 

.formation_presential_red{
  text-align: center;
  color: red;
  font-weight: 'bold';
  font-size: 17px;
}

.formation_presential_red:hover{
  color: red;
}

.formation_presential_red:focus{
  color: red;
}

.formation_presential_green{
  text-align: center;
  color: green;
  font-weight: 'bold';
  font-size: 17px;
}

/* ne pas changer la couleur en passant la souris */
.formation_presential_green:hover{
  color: green;
  }

/* ne pas changer la couleur apres clic */
.formation_presential_green:focus{
    color: green;
 }

 .formation_presential_purple{
  text-align: center;
  color: purple;
  font-weight: 'bold';
  font-size: 17px;
}

/* ne pas changer la couleur en passant la souris */
.formation_presential_pruple:hover{
  color: purple;
  }

/* ne pas changer la couleur apres clic */
.formation_presential_purple:focus{
    color: purple;
 }




 .formation_EnLigne_red{
  text-align: center;
  color: red;
  font-weight: 'bold';
  font-size: 17px;
}

.formation_EnLigne_red:hover{
  color: red;
}

.formation_EnLigne_red:focus{
  color: red;
}

.formation_EnLigne_green{
  text-align: center;
  color: green;
  font-weight: 'bold';
  font-size: 17px;
}

/* ne pas changer la couleur en passant la souris */
.formation_EnLigne_green:hover{
  color: green;
  }

/* ne pas changer la couleur apres clic */
.formation_EnLigne_green:focus{
    color: green;
 }

 .formation_EnLigne_purple{
  text-align: center;
  color: purple;
  font-weight: 'bold';
  font-size: 17px;
}

/* ne pas changer la couleur en passant la souris */
.formation_EnLigne_purple:hover{
  color: purple;
  }

/* ne pas changer la couleur apres clic */
.formation_EnLigne_purple:focus{
    color: purple;
 }


 
 .autre_produit_red{
  text-align: center;
  color: red;
  font-weight: 'bold';
  font-size: 17px;
}

.autre_produit_red:hover{
  color: red;
}

.autre_produit_red:focus{
  color: red;
}

.autre_produit_green{
  text-align: center;
  color: green;
  font-weight: 'bold';
  font-size: 17px;
}

/* ne pas changer la couleur en passant la souris */
.autre_produit_green:hover{
  color: green;
  }

/* ne pas changer la couleur apres clic */
.autre_produit_green:focus{
    color: green;
 }

 .autre_produit_purple{
  text-align: center;
  color: purple;
  font-weight: 'bold';
  font-size: 17px;
}

.autre_produit_purple:hover{
  color: purple;
}

.autre_produit_purple:focus{
  color: purple;
}




 .panier_red{
  text-align: center;
  color: red;
  font-weight: 'bold';
  /*font-size: 20px;*/
}

.panier_red:hover{
  color: red;
}

.panier_red:focus{
  color: red;
}


.panier_green{
  text-align: center;
  color: green;
  font-weight: 'bold';
  /*font-size: 20px;*/
}

.panier_green:hover{
  color: green;
}

.panier_green:focus{
  color: green;
}

.colorAuthentificationUser_green{
  text-align: center;
  color: green;
  font-weight: 'bold';
  /*font-size: 16px;*/
}

.colorAuthentificationUser_green:hover{
  color: green;
}

.colorAuthentificationUser_green:focus{
  color: green;
}

.colorAuthentificationUser_red{
  text-align: center;
  color: red;
  font-weight: 'bold';
  /*font-size: 16px;*/
}

.colorAuthentificationUser_red:hover{
  color: red;
}

.colorAuthentificationUser_red:focus{
  color: red;
}
