.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.card-body {
  padding: 15px;
}

.card-text {
  margin-bottom: 0;
}

.btn-danger {
  margin-top: 10px;
}

.sam {
  width: 100%;
  max-width: 50%;
} 



.playlist-container {
  display: flex;
  width: 100%;
}

.leftplaylist {
  flex: 1; 
  padding-right: 10px; 
}

.rightplaylist {
  flex: 1; /* Take up the other half of the space */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto; /* Ensure it is aligned to the right side */
  padding-left: 10px;
  margin-right: -136%;
}

.playlist-item {
  margin-bottom: 5px;
  cursor: pointer;
}

    .videos{
    width: 850px !important;
    height: auto !important;
    }


/* Media queries for different screen sizes */
/* Laptop  */

@media (max-width: 1366px) {
    .videos{
    width: fit-content;
    height: auto;
    }

    .rightplaylist {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    padding-left: 10px;
    margin-right: -162%;
}
  }

  /* For Small Laptop */

  @media (max-width: 1326px) {
    .videos{
    width: fit-content;
    height: auto;
    }
    .rightplaylist {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto;
    padding-left: 10px;
    margin-right: -169%;
}


  }


  /* For Typical Smartphone ( Redmi Note 10 ) */

  @media (max-width: 398px) {

    .videos{
        width: 350px !important;
        height: 200px !important;
        margin-top: 6px !important;
    }


    .rightplaylist {
        margin-top: 0%;
        margin-left: 94%;

}
  }

 