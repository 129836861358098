.contact-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .contact-form {
    background-color: #f7f7f7;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    margin-top: 30px;
    border: 2px solid #212529;
  }
  
  .contact-form h1 {
    font-size: 36px;
    margin-left: 30px;
  }
  
  .contact-form label {
    font-weight: bold;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form button {
    margin-top: 20px;
  }
  

  .formBasicMessage {
    margin-top: auto;
  }


  .text-start-content {
    text-align: left;
    padding-left: 20px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  
  .text-start-content h6 {
    margin: 25px;
    line-height: 1.5;
  }
  
  .text-start-content a {
    text-decoration: none;
    color: #212529;
  }
  
  .text-start-content a:hover {
    text-decoration: underline;
  }
  
  .og {
    margin-top: 10%;
    margin-bottom: 0%;
    margin-right: 0%;
    margin-left: 0%;
    /* margin-left: -26%; */
  }
  .og2 {
    margin-top: 10%;
    margin-bottom: 0%;
    margin-right: 0%;
    margin-left: 0%;
  }
  
  
  .btn.custom-button {
    background-color: #fff; /* Change the background color to your desired color */
    color: #212529; /* Change the text color to your desired color */
    font-size: 18px; /* Change the font size to your desired size */
    padding: 10px 20px; /* Adjust the padding as needed */
    border: 2px solid #212529;
    border-radius: 5px; /* Adjust the border radius as needed */
    cursor: pointer;
    border-color:#212529 ;
  }
  
  .btn.custom-button:hover {
    background-color: #212529; /* Change the background color on hover */
    border-color:#212529 ;
  }
  
  .center-content {
    text-align: center;
  }

  .form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #212529;
}