body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

.certificate-preview h2,
.certificate-preview p {
  color: #000;
}

/* .row {
  transition: all 0.3s ease;
  --bs-gutter-x: -0.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-bottom: 0;
  overflow-y : auto;
} */

.middle-side {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-side p {
  margin-top: 20px;
}

.template-image {
  width: 200px;
  height: 200px;
  margin-right: 10px;
  cursor: pointer;
}

.selected {
  border: 2px solid blue;
}

.template-preview {
  text-align: center;
  margin-bottom: 30px;
}

.left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.templates {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.template-image {
  width: 300px;
  height: 300px;
  margin-bottom: 10px;
  cursor: pointer;
}

.image-container {
  position: relative;
}

.overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: black;
}

.slider {
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 50px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logo-img {
  margin-top: -9%;
  margin-right: 0%;
  margin-bottom: 2%;
  margin-left: 60%;
  height: 100px;
  width: 100px;
}

.slider::before {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.slide-left {
  background-color: lightskyblue;
}

.slide-left::before {
  transform: translateX(0);
}

.slide-right {
  background-color: lightskyblue;
}

.slide-right::before {
  transform: translateX(30px);
}

.slider-content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slide-button {
  width: 50px;
  height: 20px;
  background-color: #ccc;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.theme-text {
  display: flex;
  align-items: center;
  margin-left: -124px;
  margin-top: -5%;
}

.content {
  padding-bottom: 0;
  overflow: hidden;
}

.dark-theme-content {
  margin-bottom: 344px;
}

.overlay h5 {
  font-size: 1.30rem;
}

.formation_header {
  position: absolute;
  top: 24%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.formation {
  position: absolute;
  top: 58%;
  left: 51%;
  text-align: right;
  white-space: nowrap;
}

.nom {
  position: absolute;
  top: 48%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.cin {
  position: absolute;
  top: 54%;
  left: -17%;
  right: 0%;
}

.date {
  position: absolute;
  top: 72%;
  left: -60%;
  right: 0%;
}

.qr-code {
  position: absolute;
  height: 75px !important;
  width: 75px !important;
  top: 67%;
  bottom: 0%;
  right: 47%;
}

.validite{
  position: absolute;
  top: 77%;
  left: 0%;
  right: 0%;
}

.signature{
  position: absolute;
  top: 63%;
  left: 66%;
  right: 0%;
  height: 280px !important;
  width: 280px !important;
}

.nom2 {
  position: absolute;
  top: 51%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.cin2 {
  position: absolute;
  top: 59%;
  left: -21%;
  right: 0%;
}

.date2 {
  position: absolute;
  top: 72%;
  left: -60%;
  right: 0%;
}

.qr-code2 {
  position: absolute;
  height: 75px !important;
  width: 75px !important;
  top: 67%;
  bottom: 0%;
  right: 47%;
}

.validite2{
  position: absolute;
  top: 77%;
  left: 0%;
  right: 0%;
}

.signature2{
  position: absolute;
  top: 63%;
  left: 66%;
  right: 0%;
  height: 280px !important;
  width: 280px !important;
}

.nom3 {
position: absolute;
    top: 46%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.cin3 {
    position: absolute;
    top: 56.5%;
    left: -62%;
    right: 0%;
}

.date3 {
    position: absolute;
    top: 77%;
    left: -51%;
    right: 0%;
}

.qr-code3 {
    position: absolute;
    height: 90px !important;
    width: 90px !important;
    top: 69%;
    bottom: 0%;
    right: 45%;
}

.validite3{
    position: absolute;
    top: 83%;
    left: 2%;
    right: 0%;
}

.signature3{
position: absolute;
    top: 66%;
    left: 71%;
    right: 0%;
    height: 280px !important;
    width: 280px !important;
}


.nom4 {
    position: absolute;
    top: 49%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.cin4 {
    position: absolute;
    top: 59.5%;
    left: -27%;
    right: 0%;
}

.date4 {
    position: absolute;
    top: 77%;
    left: -63%;
    right: 0%;
}

.qr-code4 {
  position: absolute;
    height: 90px !important;
    width: 90px !important;
    top: 69%;
    bottom: 0%;
    right: 49%;
}

.validite4{
    position: absolute;
    top: 82%;
    left: 0%;
    right: 0%
}

.signature4{
    position: absolute;
    top: 67%;
    left: 61%;
    right: 0%;
    height: 280px !important;
    width: 280px !important;
}




.col-3 {
  flex: 0 0 auto !important;
  width: 100% !important;
}

.col-2 {
  flex: 1 0 auto !important;
  width: 50% !important;
}

/* Media queries for different screen sizes */
/* Laptop  */

@media (max-width: 1366px) {
  .overlay h5 {
    font-size: 1.15rem;;
  }

  .overlay h2 {
    font-size: 2.25rem;
    
  }

  .formation_header {
    position: absolute;
    top: 24%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .formation {
    position: absolute;
    top: 58%;
    left: 51%;
    text-align: right;
    white-space: nowrap;
  }
  
  .nom {
    position: absolute;
    top: 48%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .cin {
    position: absolute;
    top: 54%;
    left: -17%;
    right: 0%;
  }
  
  .date {
    position: absolute;
    top: 72%;
    left: -60%;
    right: 0%;
  }
  
  .qr-code {
    position: absolute;
    height: 75px !important;
    width: 75px !important;
    top: 67%;
    bottom: 0%;
  }

.nom3 {
    position: absolute;
    top: 44%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.cin3 {
    position: absolute;
    top: 56.5%;
    left: -62%;
    right: 0%;
}

.date3 {
    position: absolute;
    top: 77%;
    left: -51%;
    right: 0%;
}

.qr-code3 {
    position: absolute;
    height: 90px !important;
    width: 90px !important;
    top: 69%;
    bottom: 0%;
    right: 45%;
}

.validite3{
    position: absolute;
    top: 83%;
    left: 2%;
    right: 0%;
}

.signature3{
    position: absolute;
    top: 64%;
    left: 68%;
    right: 0%;
    height: 280px !important;
    width: 280px !important;
}


 .nom4 {
        position: absolute;
        top: 47%;
        bottom: 0%;
        left: 0%;
        right: 0%;
}

.cin4 {
        position: absolute;
        top: 59.5%;
        left: -27%;
        right: 0%;
}

.date4 {
        position: absolute;
        top: 77%;
        left: -62%;
        right: 0%;
}

.qr-code4 {
        position: absolute;
        height: 90px !important;
        width: 90px !important;
        top: 69%;
        bottom: 0%;
        right: 47%;
}

.validite4{
        position: absolute;
        top: 82%;
        left: 2%;
        right: 0%;
}

.signature4{
        position: absolute;
        top: 66%;
        left: 61%;
        right: 0%;
        height: 280px !important;
        width: 280px !important;
}

}

/* For Small Laptop */

@media (max-width: 1326px) {
  .overlay h5 {
    font-size: 1.15rem;
  }

  .overlay h2 {
    font-size: 2.25rem;
    
  }

  .formation_header {
    position: absolute;
    top: 24%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .formation {
    position: absolute;
    top: 58%;
    left: 51%;
    text-align: right;
    white-space: nowrap;
  }
  
  .nom {
    position: absolute;
    top: 48%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .cin {
    position: absolute;
    top: 54%;
    left: -17%;
    right: 0%;
  }
  
  .date {
    position: absolute;
    top: 72%;
    left: -60%;
    right: 0%;
  }
  
  .qr-code {
    position: absolute;
    height: 75px !important;
    width: 75px !important;
    top: 67%;
    bottom: 0%;
  }

  .nom3 {
    position: absolute;
    top: 44%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.cin3 {
    position: absolute;
    top: 56.5%;
    left: -62%;
    right: 0%;
}

.date3 {
    position: absolute;
    top: 77%;
    left: -51%;
    right: 0%;
}

.qr-code3 {
    position: absolute;
    height: 90px !important;
    width: 90px !important;
    top: 69%;
    bottom: 0%;
    right: 45%;
}

.validite3{
    position: absolute;
    top: 83%;
    left: 2%;
    right: 0%;
}

.signature3{
    position: absolute;
    top: 64%;
    left: 68%;
    right: 0%;
    height: 280px !important;
    width: 280px !important;
}

  .nom4 {
        position: absolute;
        top: 47%;
        bottom: 0%;
        left: 0%;
        right: 0%;
}

.cin4 {
        position: absolute;
        top: 59.5%;
        left: -27%;
        right: 0%;
}

.date4 {
        position: absolute;
        top: 77%;
        left: -62%;
        right: 0%;
}

.qr-code4 {
        position: absolute;
        height: 90px !important;
        width: 90px !important;
        top: 69%;
        bottom: 0%;
        right: 47%;
}

.validite4{
        position: absolute;
        top: 82%;
        left: 2%;
        right: 0%;
}

.signature4{
        position: absolute;
        top: 66%;
        left: 61%;
        right: 0%;
        height: 280px !important;
        width: 280px !important;
}

}

/* For Typical Smartphone ( Redmi Note 10 ) */

@media (max-width: 398px) {
  body {
    margin: 0;
    padding: 0;
    overflow: auto;
  }

  .overlay h5 {
    font-size: 0.4rem;
  }

  .overlay h2 {
    font-size: 0.6rem;
    
  }


  .col-3 {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .col-6 {
    flex: 0 0 auto;
    width: 100% !important;
  }

  .col-2 {
    flex: 0 0 auto;
    width: 50% !important;
  }

  .formation_header {
    position: absolute;
    top: 24%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .formation {
    position: absolute;
    top: 58%;
    left: 51%;
    text-align: right;
    white-space: nowrap;
  }
  
  .nom {
    position: absolute;
    top: 48%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .cin {
    position: absolute;
    top: 54%;
    left: -17%;
    right: 0%;
  }
  
  .date {
    position: absolute;
    top: 72%;
    left: -60%;
    right: 0%;
  }
  
  .qr-code {
    position: absolute;
    height: 15px !important;
    width: 15px !important;
    top: 67%;
    bottom: 0%;
  }

  .validite{
    position: absolute;
    top: 77%;
    left: 0%;
    right: 0%;
  }
  
  .signature{
    position: absolute;
    top: 65%;
    left: 66%;
    right: 0%;
    height: 80px !important;
    width: 80px !important;
  }

  .nom2 {
    position: absolute;
    top: 51%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .cin2 {
    position: absolute;
    top: 59%;
    left: -21%;
    right: 0%;
  }
  
  .date2 {
    position: absolute;
    top: 72%;
    left: -60%;
    right: 0%;
  }
  
  .qr-code2 {
    position: absolute;
    height: 20px !important;
    width: 20px !important;
    top: 67%;
    bottom: 0%;
    right: 47%;
  }
  
  .validite2{
    position: absolute;
    top: 77%;
    left: 0%;
    right: 0%;
  }
  
  .signature2{
    position: absolute;
    top: 65%;
    left: 66%;
    right: 0%;
    height: 80px !important;
    width: 80px !important;
  }

  .nom3 {
    position: absolute;
    top: 44%;
    bottom: 0%;
    left: 0%;
    right: 0%;
}

.cin3 {
    position: absolute;
    top: 56.5%;
    left: -62%;
    right: 0%;
}

.date3 {
    position: absolute;
    top: 77%;
    left: -51%;
    right: 0%;
}

.qr-code3 {
        position: absolute;
        height: 30px !important;
        width: 30px !important;
        top: 69%;
        bottom: 0%;
        right: 45%;
}

.validite3{
    position: absolute;
    top: 83%;
    left: 2%;
    right: 0%;
}

.signature3{
    position: absolute;
    top: 65%;
    left: 66%;
    right: 0%;
    height: 80px !important;
    width: 80px !important;}

    .nom4 {
        position: absolute;
        top: 48%;
        bottom: 0%;
        left: 0%;
        right: 0%;
}

.cin4 {
        position: absolute;
        top: 59.5%;
        left: -26%;
        right: 0%;
}

.date4 {
        position: absolute;
        top: 77%;
        left: -63%;
        right: 0%;
}

.qr-code4 {
        position: absolute;
        height: 30px !important;
        width: 30px !important;
        top: 69%;
        bottom: 0%;
        right: 45%;
}

.validite4{
        position: absolute;
        top: 82%;
        left: -4%;
        right: 0%;
}

.signature4{
        position: absolute;
        top: 66%;
        left: 62%;
        right: 0%;
        height: 80px !important;
        width: 80px !important;
    } 
  
  }






@media print {
  /* Set the page to landscape mode */
  @page {
    size: A4 landscape;
    margin: 0;
  }



  .middle-side {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }

  .overlay h5 {
    font-size: 1.15rem;
  }

  .overlay h2 {
    font-size: 2.2rem;
    
  }

  .formation_header {
    position: absolute;
    top: 24%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .formation {
    position: absolute;
    top: 58%;
    left: 51%;
    text-align: right;
    white-space: nowrap;
  }
  
  .nom {
    position: absolute;
    top: 48%;
    bottom: 0%;
    left: 0%;
    right: 0%;
  }
  
  .cin {
    position: absolute;
    top: 54%;
    left: -17%;
    right: 0%;
  }
  
  .date {
    position: absolute;
    top: 72%;
    left: -60%;
    right: 0%;
  }
  
  .qr-code {
    position: absolute;
    height: 75px !important;
    width: 75px !important;
    top: 65%;
    right: 46%;
    bottom: 0%;
  }
}
